import React from "react";
import { graphql } from "gatsby";
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import Hero from '../components/Hero';
import { Helmet } from 'react-helmet';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, fields, html } = markdownRemark
  return (
    <Layout>
    <Hero
      coverImage={'https://hollismeddings.com'+frontmatter.image}
      primaryContent={
        <span>{frontmatter.title}</span>
      }
    />

    <div className="container mx-auto px-16 py-32 mb-12">
        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">{frontmatter.title}</h1>
        <div
          className="leading-relaxed text-base blog-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        {/* <a className="py-16 pdf-link" href={`/pdf/hollis-meddings-news-${fields.slug}.pdf`} target="_blank">Download PDF</a> */}
    </div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{ frontmatter.title } | Hollis Meddings Group</title>
      <meta name="description" content="Hollis Meddings Group understands the practical realities of doing business in uncertain times as well as the challenges of running a family based entity where management and ownership are often the same."/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta property="og:title" content={ 'Hollis Meddings Group - '+frontmatter.title }/>
      <meta property="og:type" content="website" />
      <meta property="og:description" content="Hollis Meddings Group understands the practical realities of doing business in uncertain times as well as the challenges of running a family based entity where management and ownership are often the same."/>
      <meta property="og:image" content={ 'https://www.hollismeddings.com'+frontmatter.image }/>
      <link rel="canonical" href={'https://www.hollismeddings.com/news'+frontmatter.path} />
      <meta property="og:url" content={'https://www.hollismeddings.com'+frontmatter.path} />
      <meta name="twitter:card" content={ 'https://www.hollismeddings.com'+frontmatter.image }/>
      <meta property="og:site_name" content="Hollis Meddings Group"/>
      <meta name="twitter:image:alt" content="Hollis Meddings Group"/>
    </Helmet>
  </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        image
      }
      fields {
        slug
      }
    }
  }
`